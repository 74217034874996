<template>
  <v-dialog v-model="dialog" max-width="500px" content-class="collection-dialog">
    <!-- Mostrar LoginCard si l'usuari no està autenticat -->
    <v-card v-if="!isAuthenticated" class="collection-dialog-card">
      <v-card-title class="headline font-rubik font-weight-bold pb-6" style="color: rgb(46, 56, 86)">
        Inicia sessió per guardar problemes
      </v-card-title>

      <v-card-text class="px-6 pb-6">
        <p class="font-rubik mb-6">Per poder guardar problemes a les teves col·leccions, necessites iniciar sessió o crear un compte.</p>

        <login-card
          :redirect="currentPath"
          :show-logo="false"
          :flat="true"
        ></login-card>
      </v-card-text>
    </v-card>

    <!-- Contingut normal del diàleg si l'usuari està autenticat -->
    <v-card v-else class="collection-dialog-card">
      <v-card-title class="headline font-rubik font-weight-bold pb-6" style="color: rgb(46, 56, 86)">
        Afegir a col·lecció
      </v-card-title>

      <!-- Cercador de col·leccions -->
      <div class="px-6 pb-4">
        <v-text-field
          v-model="searchQuery"
          :prepend-inner-icon="mdiMagnify"
          label="Cerca una col·lecció"
          hide-details
          dense
          outlined
          class="mb-4 rounded-lg"
          color="primary"
        ></v-text-field>
      </div>

      <!-- Crear nova col·lecció -->
      <v-list class="pa-0">
        <v-list-item @click="showNewCollectionField = !showNewCollectionField" class="new-collection-item px-6">
          <v-list-item-icon class="mr-4">
            <v-icon color="primary">{{ mdiPlus }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-rubik">Nova col·lecció</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- Camp per crear nova col·lecció -->
      <div v-if="showNewCollectionField" class="px-6 py-3">
        <v-text-field
          v-model="newCollectionName"
          label="Nom de la col·lecció"
          outlined
          dense
          class="font-rubik rounded-lg"
          placeholder="Introdueix el nom de la col·lecció"
          hide-details
        ></v-text-field>
      </div>

      <v-divider></v-divider>

      <!-- Secció de col·leccions guardades -->
      <div class="px-6 pt-4 pb-2">
        <div class="font-rubik font-weight-medium" style="color: rgba(46, 56, 86, 0.7)">
          Col·leccions guardades
        </div>
      </div>

      <!-- Llista de col·leccions -->
      <v-list class="collection-list pa-0">
        <v-list-item
          v-for="collection in filteredCollections"
          :key="collection.collection_id"
          class="px-6"
          @change="toggleProblemInCollection(collection.collection_id)"
          >
          <v-list-item-icon class="mr-4">
            <v-avatar color="primary" size="40" class="white--text">
              <span class="font-weight-bold">{{ getCollectionInitials(collection.collection_name) }}</span>
            </v-avatar>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-rubik">{{ collection.collection_name }}</v-list-item-title>
            <v-list-item-subtitle class="font-rubik">
              {{ collection.problem_count || 0 }} {{ collection.problem_count === 1 ? 'problema' : 'problemes' }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-checkbox
                  :input-value="isProblemInCollection(collection.collection_id)"
                  @change="toggleProblemInCollection(collection.collection_id)"
                  hide-details
                  class="ma-0 pa-0"
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  :disabled="isLoading"
                ></v-checkbox>
              </template>
              <span>{{ isProblemInCollection(collection.collection_id) ? 'Eliminar problema d\'aquesta col·lecció' : 'Afegir problema a aquesta col·lecció' }}</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider v-if="collections.length > 0"></v-divider>

      <!-- Missatge de resultat -->
      <v-alert
        v-if="resultMessage"
        :type="resultMessageType"
        dense
        class="mx-6 mt-4"
        transition="scale-transition"
      >
        {{ resultMessage }}
      </v-alert>

      <!-- Botons d'acció -->
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn
          text
          class="text-none font-rubik mr-2"
          @click="onCancel"
        >
          Cancel·lar
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!canSave"
          :loading="loading"
          @click="onSave"
          class="text-none font-weight-bold font-rubik rounded-lg white--text px-6"
          depressed
          height="46"
        >
          Fet
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Diàleg de confirmació d'eliminació -->
    <v-dialog v-model="deleteDialog" max-width="500" content-class="rounded-xl shadow-md">
      <v-card class="rounded-xl d-flex flex-column"
              max-height="100%"
              style="overflow-y: hidden"
      >
        <v-card-title class="py-0">
          <h2 class="px-6 py-4" style="font-size: 22px !important;">Eliminar col·lecció</h2>
          <v-spacer></v-spacer>
          <v-icon @click="deleteDialog=false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <div
          class="px-12 py-4"
          style="min-height: 200px; flex: 1; flex-direction: column; background-color: rgb(245 245 245)">

          <div class="my-auto py-8">
            <p class="mb-2">Estàs segur que vols eliminar la col·lecció <span class="font-weight-bold">{{ collectionToDelete ? collectionToDelete.collection_name : '' }}</span>?</p>
            <p class="red--text">Aquesta acció no es pot desfer i s'eliminaran tots els problemes guardats a la col·lecció.</p>

            <div class="d-flex mt-8">
              <v-spacer></v-spacer>
              <v-btn
                text
                class="mr-4"
                @click="deleteDialog = false"
              >
                Cancel·lar
              </v-btn>
              <v-btn
                color="error"
                :loading="deletingCollection"
                @click="deleteCollection"
                depressed
                class="text-none shadow-small rounded tit px-6"
              >
                Eliminar
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mdiPlus, mdiMagnify, mdiDelete, mdiClose, mdiCheck } from '@/assets/mdi.json';
import LoginCard from '@/views/profile/login/components/LoginCard.vue';

export default {
  name: "CollectionDialog",
  components: {
    LoginCard
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    collections: {
      type: Array,
      default: () => []
    },
    problemsMap: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    problemId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      mdiPlus: mdiPlus,
      mdiMagnify: mdiMagnify,
      mdiDelete: mdiDelete,
      mdiClose: mdiClose,
      mdiCheck: mdiCheck,
      selectedCollections: [],
      newCollectionName: '',
      searchQuery: '',
      showNewCollectionField: false,
      resultMessage: '',
      resultMessageType: 'success',
      isLoading: false,
      url: process.env.VUE_APP_AXIOS_URI || 'https://examenselectivitat.cat:3000',
      deleteDialog: false,
      collectionToDelete: null,
      deletingCollection: false,
      addedCollections: [],
      removedCollections: []
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    filteredCollections() {
      if (!this.searchQuery) return this.collections;

      const query = this.searchQuery.toLowerCase();
      return this.collections.filter(collection =>
        collection.collection_name.toLowerCase().includes(query)
      );
    },
    canSave() {
      return this.newCollectionName && this.newCollectionName.trim().length > 0;
    },
    isAuthenticated() {
      return this.$root && this.$root.token && this.$root.token.value;
    },
    currentPath() {
      return this.$route.fullPath;
    }
  },
  watch: {
    value(newVal) {
      if (newVal) {
        // Reset form when dialog opens
        this.selectedCollections = [];
        this.newCollectionName = '';
        this.searchQuery = '';
        this.showNewCollectionField = false;
        this.resultMessage = '';
        this.addedCollections = [];
        this.removedCollections = [];
      }
    }
  },
  methods: {
    getCollectionInitials(name) {
      if (!name) return '?';

      const words = name.split(' ');
      if (words.length === 1) {
        return name.substring(0, 2).toUpperCase();
      }

      return (words[0].charAt(0) + words[1].charAt(0)).toUpperCase();
    },
    onCancel() {
      this.dialog = false;
    },
    toggleCollectionSelection(collectionId) {
      // Si el problema ja està a la col·lecció, no fem res
      if (this.isProblemInCollection(collectionId)) {
        return;
      }

      const index = this.selectedCollections.indexOf(collectionId);
      if (index === -1) {
        this.selectedCollections.push(collectionId);
      } else {
        this.selectedCollections.splice(index, 1);
      }
    },
    isCollectionSelected(collectionId) {
      // Comprova si el problema està a la col·lecció (ja sigui al mapa original o afegit durant aquesta sessió)
      // i no ha estat eliminat durant aquesta sessió
      return this.isProblemInCollection(collectionId);
    },
    isProblemInCollection(collectionId) {
      // Si l'hem eliminat durant aquesta sessió, no està a la col·lecció
      if (this.removedCollections.includes(collectionId)) {
        return false;
      }

      // Si l'hem afegit durant aquesta sessió, està a la col·lecció
      if (this.addedCollections.includes(collectionId)) {
        return true;
      }

      // Comprova si el problema ja està al mapa de problemes original
      return this.problemsMap[collectionId] &&
             this.problemsMap[collectionId].includes(parseInt(this.problemId));
    },
    async toggleProblemInCollection(collectionId) {
      this.isLoading = true;

      try {
        // Si el problema ja està a la col·lecció, el treiem
        if (this.isProblemInCollection(collectionId)) {
          await this.removeProblemFromCollection(collectionId);
        } else {
          await this.addProblemToCollection(collectionId);
        }

        // Actualitzar la llista de col·leccions
        this.$emit('collections-updated');
      } catch (error) {
        console.error('Error:', error);
        this.showResultMessage('Error en modificar la col·lecció. Torna-ho a provar.', 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async removeProblemFromCollection(collectionId) {
      if (!collectionId) {
        throw new Error('ID de col·lecció no vàlid');
      }

      console.log('Eliminant problema de la col·lecció:', {
        problem_id: this.problemId,
        collection_id: collectionId
      });

      const response = await axios.delete(`${this.url}/problems/collections`, {
        headers: {
          Authorization: this.$root?.token?.value,
          'Content-Type': 'application/json'
        },
        data: {
          problem_id: this.problemId,
          collection_id: collectionId
        }
      });

      // Si s'ha eliminat correctament, ho marquem com a eliminat
      if (response.data.success) {
        // Si estava a la llista d'afegits, el treiem
        const addedIndex = this.addedCollections.indexOf(collectionId);
        if (addedIndex !== -1) {
          this.addedCollections.splice(addedIndex, 1);
        } else {
          // Si no, l'afegim a la llista d'eliminats
          this.removedCollections.push(collectionId);
        }

        // Actualitzem el recompte de problemes
        this.collections.forEach(collection => {
          if (collection.collection_id === collectionId) {
            collection.problem_count = Math.max(0, (collection.problem_count || 0) - 1);
          }
        });

        this.showResultMessage('Problema eliminat de la col·lecció correctament', 'success', false);
      }

      return response.data;
    },
    async onSave() {
      this.isLoading = true;

      try {
        // Només creem una nova col·lecció si hi ha un nom
        if (this.newCollectionName && this.newCollectionName.trim().length > 0) {
          await this.createAndAddToCollection(this.newCollectionName.trim());
          this.showResultMessage('Col·lecció creada i problema afegit correctament!', 'success');
        } else {
          this.dialog = false;
        }
      } catch (error) {
        console.error('Error:', error);
        this.showResultMessage('Error en crear la col·lecció. Torna-ho a provar.', 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async createAndAddToCollection(collectionName) {
      try {
        if (!collectionName || collectionName.trim() === '') {
          throw new Error('El nom de la col·lecció no pot estar buit');
        }

        // Crear nova col·lecció
        const createResponse = await axios.post(`${this.url}/collections`, {
          collection_name: collectionName
        }, {
          headers: {
            Authorization: this.$root?.token?.value
          }
        });

        // Obtenir l'ID de la nova col·lecció
        const collectionId = createResponse.data.collection_id;

        if (!collectionId) {
          throw new Error('No s\'ha pogut obtenir l\'ID de la col·lecció creada');
        }

        // Afegir problema a la col·lecció
        await this.addProblemToCollection(collectionId);

        // Actualitzar la llista de col·leccions
        this.$emit('collections-updated');
      } catch (error) {
        console.error('Error creant la col·lecció:', error);
        this.showResultMessage('Error en crear la col·lecció. Torna-ho a provar.', 'error');
        throw error;
      }
    },
    async addProblemToCollection(collectionId) {
      if (!collectionId) {
        throw new Error('ID de col·lecció no vàlid');
      }

      console.log('Afegint problema a col·lecció:', {
        problem_id: this.problemId,
        collection_id: collectionId
      });

      const response = await axios.post(`${this.url}/problems/collections`, {
        problem_id: this.problemId,
        collection_id: collectionId
      }, {
        headers: {
          Authorization: this.$root?.token?.value
        }
      });

      // Si s'ha afegit correctament o ja existia, ho marquem com a afegit
      if (response.data.success || response.data.alreadyExists) {
        // Si estava a la llista d'eliminats, el treiem
        const removedIndex = this.removedCollections.indexOf(collectionId);
        if (removedIndex !== -1) {
          this.removedCollections.splice(removedIndex, 1);
        } else {
          // Si no, l'afegim a la llista d'afegits
          this.addedCollections.push(collectionId);
        }

        // Actualitzem el recompte de problemes si s'ha afegit correctament
        if (response.data.success) {
          this.collections.forEach(collection => {
            if (collection.collection_id === collectionId) {
              collection.problem_count = (collection.problem_count || 0) + 1;
            }
          });
        }

        this.showResultMessage('Problema afegit a la col·lecció correctament', 'success', false);
      }

      return response.data;
    },
    showResultMessage(message, type = 'success', closeDialog = true) {
      this.resultMessage = message;
      this.resultMessageType = type;

      // Tancar el diàleg després d'un temps si és un missatge d'èxit i closeDialog és true
      if (type === 'success' && closeDialog) {
        setTimeout(() => {
          this.dialog = false;
          // Actualitzar la llista de col·leccions
          this.$emit('collections-updated');
        }, 2000);
      }
    },
    confirmDelete(collection) {
      this.collectionToDelete = collection;
      this.deleteDialog = true;
    },
    async deleteCollection() {
      if (!this.collectionToDelete) return;

      this.deletingCollection = true;
      try {
        await axios.delete(`${this.url}/collections/${this.collectionToDelete.collection_id}`, {
          headers: {
            Authorization: this.$root?.token?.value
          }
        });

        // Actualitzar la llista de col·leccions
        this.$emit('collections-updated');

        // Mostrar missatge d'èxit
        this.showResultMessage('Col·lecció eliminada correctament', 'success');
        this.deleteDialog = false;
      } catch (error) {
        console.error('Error eliminant la col·lecció:', error);
        this.showResultMessage('Error en eliminar la col·lecció', 'error');
      } finally {
        this.deletingCollection = false;
        this.collectionToDelete = null;
      }
    }
  }
}
</script>

<style scoped>
.collection-dialog-card {
  border-radius: 0.75rem !important;
  overflow: hidden;
  background-color: #fff;
}

.search-field {
  background-color: #f5f5f5 !important;
}

.search-field >>> .v-input__slot {
  min-height: 44px !important;
}

.collection-list {
  max-height: 300px;
  overflow-y: auto;
}

.new-collection-item {
  transition: background-color 0.2s ease;
}

.new-collection-item:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

/* Estil per a la barra de desplaçament */
.collection-list::-webkit-scrollbar {
  width: 8px;
}

.collection-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.collection-list::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.collection-list::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
</style>
